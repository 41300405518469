import { useState } from 'react'
import { Grid, Button, Box } from "@mui/material"
import { RecordFieldValue } from '../../../Components/RecordFieldValue'

const RecordFieldRow = ({ name, label, value, fieldType, options, editMode }) => {
  return (
    <>
      <Grid item xs={4} paddingTop={2} paddingBottom={4}>{label}</Grid>
      <Grid item xs={8}>
        <RecordFieldValue name={name} label={label} value={value} fieldType={fieldType} options={options} editMode={editMode} />
      </Grid>
    </>
  )
}

export const GenericRecord = ({ fieldList, data }) => {
  const [editMode, setEditMode] = useState(false)
  const createRecord = (evt) => {
    evt.preventDefault();
    const formData = new FormData(evt.target);
    const data = {}
    for (const [key, value] of formData) {
      data[key] = value
    }
    console.log('submitting data', data)

    
  }
  return (
    <>
      <Box component='form' onSubmit={createRecord}>
        <Grid container>  
          {fieldList.map(({ name, label, fieldType, options }) =>
            <RecordFieldRow key={`field-${name}`} name={name} label={label} value={data[name]} fieldType={fieldType} options={options} editMode={editMode} />,
          )}
          <Grid item xs={12}>
            <Button variant={editMode ? 'text' : 'outlined'} onClick={() => setEditMode(!editMode)}>
              {editMode ? 'Cancel Edit' : 'Create new Version'}
            </Button>
            {editMode && <Button marginLeft={1} variant="contained" type="submit">Create Record</Button>}
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
export default GenericRecord;
