import React, { useState, useEffect } from 'react'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import JSONPretty from 'react-json-pretty';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { DisplayTool } from './DisplayTool';

const ResultsDisplay = ({ outputResults, outputMessage }) => {
  if (outputMessage) {
    return (
      <Markdown remarkPlugins={[remarkGfm]}>{outputMessage}</Markdown>
    )
  }
  // Display array of arrays as a table
  if (Array.isArray(outputResults) && Array.isArray(outputResults[0])) {
    const header = outputResults[0]
    return (
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {header.map((item) => <TableCell>{item}</TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {outputResults.slice(1).map((row, idx) => (
              <TableRow
                key={`row-${idx}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {row.map((item) => <TableCell>{item}</TableCell>)}
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
  return (
    <JSONPretty id="json-pretty" data={outputResults}></JSONPretty>
  )
}


export const ToolOutputDialog = ({ tool, initialInputs = {}, output, open, handleClose, loading, executeTool }) => {
  const [outputResults, setOutputResults] = useState('')
  const [outputMessage, setOutputMessage] = useState('')
  const [outputResultType, setOutputResultType] = useState('')
  useEffect(() => {
    const { results, message, resultType } = output
    if (message) {
      setOutputMessage(message)
    } else {
      setOutputMessage('')
    }
    if (results) {
      const jsonResults = JSON.parse(results)
      setOutputResults(jsonResults)
    } else {
      setOutputResults('')
    }
    if (resultType) {
      setOutputResultType(resultType)
    } else {
      setOutputResultType('failure')
    }
  }, [output])


  const confirmTool = (evt) => {
    console.log('handling confirm tool', evt)
    evt.preventDefault()
    console.log('submitting tool', tool, evt)
    const args = [{ name: 'confirmedValues', value: { type: 'string', value: JSON.stringify(outputResults) } }]
    console.log('got args', args)
    executeTool(tool, {
      toolArn: tool.arn,
      mode: 'confirmed',
      arguments: args,
    })
  }

  return (
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          {tool.name}
        </DialogTitle>
        <DialogContent>
          <DisplayTool tool={tool} executeTool={executeTool} initialInputs={initialInputs} />
          {loading && <CircularProgress />}
          {!loading && <DialogContentText id="alert-dialog-description">
            <ResultsDisplay outputResults={outputResults} outputMessage={outputMessage} />
          </DialogContentText>}
        </DialogContent>
        <DialogActions>
          {outputResultType === 'needsConfirmation' && <Button onClick={confirmTool}>
            Confirm Execution
          </Button>}
          <Button onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
  )
}