import { GenericRecord } from './GenericRecord';

import { RECORD_DEFINITIONS } from '../../../utils/recordDefinitions';

const LicenseRecord = ({ data }) => {
  return (
    <GenericRecord data={data} fieldList={RECORD_DEFINITIONS.license.fieldList} />
  )
}
export default LicenseRecord;
