import React, { useState, useContext, useMemo, useEffect } from 'react'
import { Grid, Card, CardContent, Box, CircularProgress, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import ApiContext from '../../../../Services/apiService';
import { milliSecondsFromDateString } from '../../../../utils/formatDate';
import { RecordFieldValue } from '../../../../Components/RecordFieldValue'
import ProofUploader from '../../Shared/ProofUploader';


const findInnerError = (err) => {
  // This is a regular JS exception
  if (err?.message) {
    return err.message
  }
  // This is a GraphQL error message array
  if (Array.isArray(err?.errors)) {
    const [{ message }] = err.errors
    return message
  }
}

const formatValue = ({ outputMap, name, value }) => {
  if (outputMap[name] === 'date' && value) {
    return String(milliSecondsFromDateString({ dateString: value }))
  }
  return value
}

const MatchInventory = ({ manualTask, setErrorDialog }) => {
  const navigator = useNavigate()
  const api = useContext(ApiContext)
  const [submitting, setSubmitting] = useState(false)
  const [found, setFound] = useState(undefined)
  const [files, setFiles] = useState([])
  // TODO:
  // Validation of values
  const vskuConfig = useMemo(() => manualTask?.taskDetails?.vskuConfig, [manualTask])
  const outputMap = useMemo(() => {
    const { outputResults } = vskuConfig
    if (Array.isArray(outputResults)) {
      return Object.fromEntries(outputResults.map(({ fieldName, dataType }) => ([fieldName, dataType])))
    }
    return {}
  }, [vskuConfig])

  const handleSubmit = useMemo(() => {
    return async (evt) => {
      evt.preventDefault();
      const data = new FormData(evt.target);
      const uploadedProof = Object.values(files).map(({ fileName, contentType }) => ({ fileName, contentType }))
      const input = {
        taskId: manualTask.taskId,
        foundResults: found,
        results: [...data.entries()].map(([name, value]) => ({ name, value: formatValue({ outputMap, name, value }) })).filter(({ value }) => value !== ''),
        uploadedProof,
      }
      console.log('constructed input', input)
      setSubmitting(true)
      try {
        const { data: { submitMatchInventory: { uploadedProofs } } } = await api.submitMatchInventory(input)
        if (uploadedProofs && uploadedProofs.length) {
          await Promise.all(
            uploadedProofs.map(({ uploadUrl, fileName, contentType, documentId }) => (api.uploadDocument({ uploadUrl, fileName, contentType, documentId, data: files[fileName].file }))))
          await api.completeProofUploadInventory({taskId: input.taskId, foundResults: input.foundResults, results: input.results, completedProof: uploadedProofs.map(({ documentId, contentType, fileName }) => ({ documentId, contentType, fileName }))})
        }
        setSubmitting(false)
        navigator('/home')
      } catch (err) {
        setSubmitting(false)
        console.log('got error', err)
        const inner = findInnerError(err)
        setErrorDialog({
          show: true,
          errorMessage: `There was an error while submitting the form. Please try again or contact #oncall - ${inner}`,
        })
      }
    }
  }, [api, navigator, found, manualTask, files, outputMap])

  const claimData = useMemo(() => {
    return Object.fromEntries(manualTask.taskDetails.claims.map(({ name, value }) => ([name, value])))
  }, [manualTask])

  return (
    <Box component='form'
      onKeyDown={evt => {
        if (evt.key === 'Enter') {
          evt.preventDefault();
          return false
        }
        return true
      }}
      onSubmit={evt => {
        console.log('got submit');
        handleSubmit(evt);
      }}>
      <Card sx={{ p: 1, m: 1 }}>
        <CardContent>
          <Grid container columnSpacing={{ xs: 2 }} rowSpacing={{ xs: 1 }}>
            <Grid item xs={6}>
              <Box>
                Inputs
                {vskuConfig && vskuConfig.inputClaims && vskuConfig.inputClaims.length && vskuConfig.inputClaims.map((field) =>
                  <RecordFieldValue
                    key={`input-${field.fieldName}`}
                    name={`input-${field.fieldName}`}
                    label={field.label}
                    value={claimData[field.fieldName] || field.value}
                    fieldType={field.dataType}
                    options={field.options}
                    editMode={false}
                  />,
                )}
              </Box>

              <Typography>Have you found a match for these inputs?</Typography>
              <Box marginTop={1}>
                <Button variant={found === true ? 'contained' : 'outlined'} fullWidth onClick={() => setFound(true)}>Yes</Button>
              </Box>
              <Box marginTop={1}>
                <Button variant={found === false ? 'contained' : 'outlined'} fullWidth onClick={() => setFound(false)}>No</Button>
              </Box>
            </Grid>
            {found && <Grid item xs={6}>
              <Box>
                Outputs
                {vskuConfig.outputResults.map((field) =>
                  <RecordFieldValue
                    key={`output-${field.fieldName}`}
                    name={field.fieldName}
                    label={field.label}
                    fieldType={field.dataType}
                    options={field.options}
                    editMode={true}
                  />,
                )}
              </Box>
            </Grid>}
          </Grid>
        </CardContent>
      </Card>
      {found && <Card sx={{ p: 1, m: 1 }}>
        <CardContent>
          <Grid container columnSpacing={{ xs: 2 }} rowSpacing={{ xs: 1 }}>
            <Grid item xs={3}>
              <Typography align="left">Attach Proof</Typography>
            </Grid>
            <Grid item xs={9}>
              <ProofUploader files={files} setFiles={setFiles} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>}
      <Card sx={{ p: 1, m: 1 }}>
        <CardContent>
          <Grid container columnSpacing={{ xs: 2 }} rowSpacing={{ xs: 1 }}>
            <Grid item xs={4} />

            <Grid item xs={4}>
              <Button disabled={found === undefined} variant='contained' fullWidth type="submit">
                {submitting ? <CircularProgress color="secondary" size={20} /> : 'Submit findings'}
              </Button>
            </Grid>
            <Grid item xs={4} />
          </Grid>
        </CardContent>
      </Card>
    </Box>
  )

}

export default MatchInventory
