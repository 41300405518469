import React from "react";
import { Container, Box, Typography, Card, CardContent, TextField, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
const RecordSelector = () => {
  const navigator = useNavigate()
  const goToRecord = (evt) => {
    evt.preventDefault();
    const data = new FormData(evt.target);
    navigator(`/record/${data.get('recordId')}`)
  }
  return (
    <Container maxWidth='xl'>
      <Card sx={{ p: 1, m: 1 }}>
        <CardContent>
          <Typography variant="h3">
            Record
          </Typography>
          <Box component='form'
            onSubmit={goToRecord}>
            <Box>
              <TextField
                label="Record ID" variant="outlined"
                id="recordId" name="recordId" />
            </Box>
            <Box>
              <Button type='submit'
                variant='contained'>Go to Record
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
      
    </Container>
    
  )
}

export default RecordSelector;
