import React, { useState } from 'react'
import Dropzone from 'react-dropzone-uploader'
import { Grid, Button } from '@mui/material'

const PASTED_FILENAME = 'paste.png'

const ProofUploader = ({ files, setFiles }) => {
  const [initialized, setInitialized] = useState(false)
  const [initialFiles, setInitialFiles] = useState([])

  const pasteScreenshot = async () => {
    const auth = await navigator.permissions.query( { name: "clipboard-read" } );
    if( auth.state !== 'denied' ) {
      const itemList = await navigator.clipboard.read();
      
      let imageType; // we will feed this later
      const item = itemList.find( item => // choose the one item holding our image
        item.types.some( type => { // does this item have our type
          if( type.startsWith( 'image/' ) ) {
            imageType = type; // store which kind of image type it is
            return true;
          }
        }),
      );
      const blob = item && await item.getType( imageType );
      if (blob && imageType) {
        const file = new File([blob], PASTED_FILENAME, { type: imageType })
        setInitialFiles([file])
        const newFiles = {
          [PASTED_FILENAME]: {
            fileName: PASTED_FILENAME,
            contentType: imageType,
            file,
          },
        }
        console.log('Setting files', newFiles)
        setFiles(newFiles)
        
      }
      setInitialized(true)
    }
  }
  const handleChangeStatus = ({ meta, file }, status) => { 
    console.log('Got change', status, meta)
    if (status === 'removed') {
      const { name } = meta
      const newFiles = {
        ...files,
        [name]: undefined,
      }
      console.log('Setting files', newFiles)
      setFiles(newFiles)
    }
    if (status === 'done') {
      const { name, type } = meta
      const newFiles = {
        ...files,
        [name]: {
          fileName: name,
          contentType: type,
          file,
        },
      }
      console.log('Setting files', newFiles)
      setFiles(newFiles)
    }
  }

  if (!initialized) {
    return (
      <Grid container>
        <Grid item xs={6}>
          <Button variant="outlined" onClick={pasteScreenshot} >
            Paste Screenshot
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button variant="outlined" onClick={() => setInitialized(true)} >
            Upload Files
          </Button>
        </Grid>
      </Grid>
    )
  }

  return (
    <Dropzone
      onChangeStatus={handleChangeStatus}
      accept='image/*'
      initialFiles={initialFiles}
    />
  )
}

export default ProofUploader
