import React, { useState } from 'react'
import { Box, FormControl, InputLabel, Select, MenuItem, TextField } from "@mui/material"
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { DEFAULT_EMPTY_VALUE, getRenderer, defaultVal } from '../../utils/fieldRenderer';

export const RecordFieldValue = ({ name, label, value, fieldType, options, editMode }) => {
  return (
    <>
      <Box marginBottom={2}>
        <RecordFieldInput name={name} label={label} value={value} fieldType={fieldType} options={options} disabled={!editMode} />
      </Box>
    </>
  )
}

const RecordFieldInput = ({ name, label, value, fieldType, options, disabled = false }) => {
  const renderer = getRenderer(fieldType)
  const emptyValue = disabled ? DEFAULT_EMPTY_VALUE : ''
  const optionsValue = options && options.values
  const displayValue = renderer(optionsValue || value, emptyValue)
  if (fieldType === 'enum') {
    return (
      <FormControl fullWidth>
        <InputLabel id={`label-${name}`}>{label}</InputLabel>
        <Select
          labelId={`label-${name}`}
          id={`dropdown-${name}`}
          name={name}
          defaultValue={value || ''}
          disabled={disabled}
          label={label}
        >
          {options.values.map(({ label, value }) => <MenuItem key={`dropdown-${name}-${value}`} value={value}>{defaultVal(label)}</MenuItem>)}
        </Select>
      </FormControl>
    )
  }


  if (fieldType === 'date') {
    const initialValue = (value === undefined || value === '') ? null : value
    const [dateValue, setDateValue] = useState(initialValue)
    return (
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DesktopDatePicker
          label={label}
          disabled={disabled}
          inputFormat="MM/dd/yyyy"
          value={dateValue}
          onChange={(val) => setDateValue(val)}
          defaultValue={value}
          renderInput={(params) => {
            return <TextField fullWidth name={name} {...params} />
            }
          } />
      </LocalizationProvider>
    )
  }

  return (
    <TextField
      id={`text-${name}`}
      name={name}
      label={label}
      disabled={disabled}
      variant="outlined"
      defaultValue={displayValue}
      fullWidth
    />
  )
}

export default RecordFieldValue;
