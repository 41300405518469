import React, { createContext } from "react"
import { API, graphqlOperation } from 'aws-amplify'
import {
  createMatch,
  createNoMatch as createNoMatchMutation,
  createProfileMatch,
  completeProofUpload as completeProofUploadMutation,
  assignTask as assignTaskMutation,
  unassignTask as unassignTaskMutation,
  submitMatchInventory as submitMatchInventoryMutation,
  completeProofUploadInventory as completeProofUploadInventoryMutation,
  submitCreateRecord as submitCreateRecordMutation,
  submitMatchRecord as submitMatchRecordMutation,
  executeTool as executeToolMutation,
} from '../graphql/mutations'
import {
  listTicketsV2 as listTicketsQuery,
  getTicketV2 as getTicketQuery,
  getDocumentation as getDocumentationQuery,
  getTools as getToolsQuery,
} from '../graphql/queries'

const ApiContext = createContext()
export default ApiContext


function ContextState() {
  const getTicketSummaries = async ({ status = 'PENDING', reverseOrder = true, lastKey }) => {
    console.log('getting tickets', status, reverseOrder, lastKey)
    const response = await API.graphql(graphqlOperation(listTicketsQuery, {
      input: {
        status,
        limit: 10,
        reverseOrder,
        lastKey,
      },
    }))
    console.log('List Tickets', response.data.listTicketsV2)
    return response.data.listTicketsV2
  }

  const getTicket = async (taskId) => {
    const response = await API.graphql(graphqlOperation(getTicketQuery, {
      taskId,
    }))
    console.log('Get Ticket', response)
    return response.data.getTicketV2
  }

  const assertSuccess = ({ result }) => {
    if (result === 'Cannot Process Task - Not Owner') {
      throw new Error(result)
    }
  }

  const createNewMatch = async (data) => {
    const response = await API.graphql(graphqlOperation(createMatch, {
      input: data,
    }))
    console.log('Create New Match', response.data.createMatch);
    assertSuccess(response.data.createMatch)
    return response
  }

  const createMatchingProfile = async (data) => {
    const response = await API.graphql(graphqlOperation(createProfileMatch, {
      input: data,
    }))
    console.log('Create Matching Profile', response.data.createProfileMatch);
    assertSuccess(response.data.createProfileMatch)
    return response.data.createProfileMatch
  }

  const createNoMatch = async (data) => {
    const response = await API.graphql(graphqlOperation(createNoMatchMutation, {
      input: data,
    }))
    console.log('Create No Match', response.data.createNoMatch);
    assertSuccess(response.data.createNoMatch)
  
    return response
  }

  const uploadDocument = async ({
    uploadUrl,
    contentType,
    data,
  }) => {
    console.log('uploading document', uploadUrl, contentType, data)
    const response = await fetch(uploadUrl, {
      method: 'PUT',
      headers: {
        'content-type': contentType,
      },
      body: data,
    })
    console.log('Done uploading', response)
    return 'Done'
  }

  const completeProofUpload = async (data) => {
    const response = await API.graphql(graphqlOperation(completeProofUploadMutation, {
      input: data,
    }))
    console.log('Complete Proof', response.data.completeProofUpload);
  
    return response
  }

  const assignTask = async ({ taskId, type }) => {
    console.log('got taskId', taskId, type)
    const operation = type === 'assign' ? assignTaskMutation : unassignTaskMutation
    const response = await API.graphql(graphqlOperation(operation, { taskId }))
    console.log('got response', response)
    return (type === 'assign') ? response.data.assignTask : response.data.unassignTask
  }

  const submitMatchInventory = async (input) => {
    const response = await API.graphql(graphqlOperation(submitMatchInventoryMutation, {
      input,
    }))
    assertSuccess(response.data.submitMatchInventory)
    console.log('match submitted', response);
  
    return response
    
  }

  const submitCreateRecord = async (input) => {
    const response = await API.graphql(graphqlOperation(submitCreateRecordMutation, {
      input,
    }))

    assertSuccess(response.data.submitCreateRecord)
    console.log('match submitted', response.data.submitCreateRecord);
    return response.data.submitCreateRecord
  }

  const submitMatchRecord = async (input) => {
    const response = await API.graphql(graphqlOperation(submitMatchRecordMutation, {
      input,
    }))

    assertSuccess(response.data.submitMatchRecord)
    console.log('match submitted', response.data.submitMatchRecord);
    return response.data.submitMatchRecord
  }

  const completeProofUploadInventory = async (input) => {
    const response = await API.graphql(graphqlOperation(completeProofUploadInventoryMutation, {
      input,
    }))
    console.log('complete proof upload submitted', response);
  
    return response
    
  }

  const getDocumentation = async (docPath) => {
    const response = await API.graphql(graphqlOperation(getDocumentationQuery, {
      docPath,
    }))
    return response.data.getDocumentation
  }

  const getTools = async () => {
    const response = await API.graphql(graphqlOperation(getToolsQuery))
    return response.data.getTools
  }

  const executeTool = async (input) => {
    const response = await API.graphql(graphqlOperation(executeToolMutation, {
      input,
    }))
    return response.data.executeTool
  }

  return {
    createNewMatch,
    createMatchingProfile,
    getTicketSummaries,
    getTicket,
    createNoMatch,
    uploadDocument,
    completeProofUpload,
    assignTask,
    submitMatchInventory,
    completeProofUploadInventory,
    submitCreateRecord,
    submitMatchRecord,
    getDocumentation,
    getTools,
    executeTool,
  }
}

const ApiContextProvider = (props) => {
  const state = ContextState()

  return (
    <ApiContext.Provider value={state}>
      {props.children}
    </ApiContext.Provider>
  )
}

export { ApiContext, ApiContextProvider }
