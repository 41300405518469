import { Dialog, DialogTitle, Typography, Grid } from '@mui/material'

export const ErrorDialog = ({
  errorMessage,
  open,
  onClose,
}) => <Dialog onClose={onClose} open={open}>
  <DialogTitle>There was an error!</DialogTitle>
  <Grid container sx={{ padding: 2 }}>
    <Grid item xs={12}>
      <Typography color="red">
        {errorMessage}
      </Typography>
    </Grid>  
  </Grid>
</Dialog>
