import React, { useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react'
import { Home, Verified, Face, Build, Logout, Close, Menu } from '@mui/icons-material';
import Logo from '../Logo/Logo';
import './Drawer.css'

function Drawer() {
  const { innerWidth: width, innerHeight: height } = window
  const [drawerState, setDrawerState] = useState(false)
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  if(width > 600) { // desktop
    return (
      <div className="panel">
        <MenuColumn signOut={signOut} />
      </div>
    )
  }
  
  return (
    <div className='panel'>
      <button onClick={()=> setDrawerState(!drawerState)}>
        <Menu sx={{color: '#000' }}/>
      </button>

      <div className="absolute" style={{ left: drawerState ? 0 : -width }} onClick={() => setDrawerState(!drawerState)}>
        <MenuColumn callback={() => setDrawerState(!drawerState)}/>
      </div>

    </div>
  )
}

const navItems = [
  {
    name: "Task Queue",
    path: "/home",
    icon: <Home />,
  },
  {
    name: "Records",
    path: "/record",
    icon: <Home />,
  },
  {
    name: "Documentation",
    path: "/docs",
    icon: <Home />,
  },
  {
    name: "Tools",
    path: "/tools",
    icon: <Build />,
  },
  {
    name: "Other",
    path: "/other",
    icon: <Home />,
  },
]
  
const MenuColumn = ({ callback, signOut }) => {
  const location = useLocation()
  return (
    <div className='drawer'>
      {callback && <button onClick={callback}><Close/></button>}
      <Logo />
      <div className="routes">
        {navItems.map((val) => (
          <NavLink
            key={val.name}
            className={`row-option ${location.pathname.startsWith(val.path) ? "isActive" : "" }`}
            to={val.path}
          >
            {val.icon}
            <h1>{val.name}</h1>
          </NavLink>
        ))}
      </div>
      

      <div className={`row-option ${location.pathname === "users" ? "isActive" : ""}`} onClick={signOut}>
        <Logout/>
        <h1>Log Out</h1>
      </div>
    </div>
  )
}

export default Drawer
