import React from 'react'
import logo from '../../Images/mesh-logo@2x.png'

const Logo = () => {
  return(
    <img src={logo}/>
  )
}

export default Logo
