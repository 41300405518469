import React from 'react'
import { Card, CardContent, Grid, Button, Box, Typography, CircularProgress, Chip } from '@mui/material';

import { formatDate } from '../../../utils/formatDate';


const TaskAssignment = ({ manualTask, assignTask, assigning }) => {
  if (assigning) {
    return (
      <Box>
        <Button variant="outlined">
          <CircularProgress size={20} />
        </Button>
      </Box>
    )
  }
  if (manualTask.assignedUser) {
    return (
      <Box>
        <Button variant="outlined" onClick={assignTask}>Unassign Task</Button>
        <Typography align="left">Assigned: {manualTask.assignedUser.email}</Typography>
      </Box>
    )
  }
  return (
    <Box>
      <Button variant="outlined" onClick={assignTask}>Assign Task to me</Button>
    </Box>
  )
}

const TaskStatus = ({ status }) => {
  const color = status === 'COMPLETED' ? 'success.main' : 'text.primary'
  return (
    <Typography align="left">
      Status: 
      <Typography color={color} component="span">
        {status}
      </Typography>
    </Typography>
  )
}

export const MatchTaskHeader = ({ manualTask, assignTask, assigning }) => {
  if (!manualTask.taskId) {
    return null
  }

  const openPrimarySources = () => {
    const { sourceLinks } = manualTask
    sourceLinks.forEach((url) => window.open(url))
  }

  return (
    <Card sx={{ p: 1, m: 1 }}>
      <CardContent>
        <Grid container>
          <Grid item lg={4} md={6} xs={12}>
            <Typography align="left">Task ID: {manualTask.taskId}</Typography>
            <TaskStatus status={manualTask.status} />
            {manualTask.completionUserObj && <Typography align="left">Completed By: {manualTask.completionUserObj.email}</Typography>}
            

          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            <Typography align="left">Created At: {formatDate({ date: manualTask.taskCreationTime })}</Typography>
            <Typography align="left">Deadline: {formatDate({ date: manualTask.taskSlaTime })}</Typography>
            {manualTask.taskAssignmentTime && <Typography align="left">Assigned At: {formatDate({ date: manualTask.taskAssignmentTime })}</Typography>}
            {manualTask.taskCompletionTime && <Typography align="left">Completed At: {formatDate({ date: manualTask.taskCompletionTime })}</Typography>}
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            <TaskAssignment manualTask={manualTask} assignTask={assignTask} assigning={assigning} />
            <Button onClick={openPrimarySources}>Open Primary data sources</Button>
          </Grid>
          <Grid item xs={12}>
            {manualTask.taskTags.map(({ label, value, url }) => url
                ? <Chip key={`task-chip-${label}`} sx={{ margin: '0.25rem' }} color='primary' label={`${label}: ${value}`} component="a" href={url} clickable />
                : <Chip key={`task-chip-${label}`} sx={{ margin: '0.25rem' }} label={`${label}: ${value}`} />,
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default MatchTaskHeader
