import React, { useState, useContext, useMemo, useEffect } from 'react'
import { useParams } from 'react-router-dom';

import { Box, Container, Button } from '@mui/material';
import { ArrowBackIosNewRounded } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import ApiContext from '../../Services/apiService'

import TaskTypeWrapper, { TaskTypeDetail, TaskTypeHeader } from './TaskTypeWrapper';
import { ErrorDialog } from './Shared/ErrorDialog';
import { MatchTaskHeader } from './Shared/MatchTaskHeader'

const TaskHandler = () => {
  const { taskId } = useParams()
  
  const [manualTask, setManualTask] = useState({})
  const [assigning, setAssigning] = useState(false)
  const [errorDialog, setErrorDialog] = React.useState({
    show: false,
    errorMessage: '',
  });
  const handleCloseError = () => {
    setErrorDialog({
      show: false,
      errorMessage: '',
    })
  }
  const api = useContext(ApiContext)

  useEffect(async () => {
    const result = await api.getTicket(taskId)
    setManualTask(result)
  }, [taskId, api])

  const assignTask = useMemo(() => {
    return async () => {
      setAssigning(true)
      console.log('assign task')
      const type = manualTask && manualTask.assignedUserId ? 'unassign' : 'assign'
      const response = await api.assignTask({ type, taskId: manualTask.taskId })
      
      console.log('got response', response)
      if (response !== 'Done') {
        setErrorDialog({
          show: true,
          errorMessage: 'Error assigning task',
        })
      }
      const newTask = await api.getTicket(manualTask.taskId)
      setManualTask(newTask)
      setAssigning(false)
    }
  }, [manualTask])

  return (
    <div className="taskQueue">
      <ErrorDialog
        errorMessage={errorDialog.errorMessage}
        open={errorDialog.show}
        onClose={handleCloseError}
      />
      <Box>
      {/* <Container maxWidth="lg" sx={{ bgcolor: '#f8f8f8', overflow: 'scroll' }}> */}

        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
          <BackButton />
          <TaskTypeHeader manualTask={manualTask} />
          {/* Empty Placeholder for proper spacing*/}
          <div />
        </Box> 
        <MatchTaskHeader manualTask={manualTask} assignTask={assignTask} assigning={assigning} />
        <TaskTypeWrapper manualTask={manualTask} setErrorDialog={setErrorDialog} />
      {/* </Container> */}
      </Box>
    </div>
  )
}

const BackButton = () => {
  const navigator = useNavigate()

  const onBackPressed = () => {
    navigator('/home')
  }
  return (
    <Button variant="text" startIcon={<ArrowBackIosNewRounded />} onClick={onBackPressed}>Back</Button>
  )
}

export default TaskHandler
