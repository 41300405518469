import { DateTime } from 'luxon'

export const formatDate = ({ date, format = DateTime.DATETIME_SHORT }) =>
  date && !isNaN(parseInt(date, 10)) && DateTime.fromMillis(parseInt(date, 10)).toLocaleString(format) || ''

// TODO: Figure out how to do this better for intl
export const milliSecondsFromDateString = ({ dateString, format = 'MM/dd/yyyy' }) =>
  // Hard-coding to Denver at noon to minimize timezone issues
  dateString && DateTime
    .fromFormat(dateString, format, { zone: 'America/Denver' })
    .plus({ hours: 12 })
    .toMillis() || ''
