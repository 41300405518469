import React, { useState, useContext, useMemo, useEffect } from 'react'
import { Box, CircularProgress } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import ApiContext from '../../../../Services/apiService';
import CompletedTicketDetail from './CompletedProfile';
import PendingTicketDetail from './PendingProfile';

import { cleanseValue } from '../../../../utils/cleanseValue'
import { ActionTypes } from '../../../../utils/constants';

const validateNotFound = (data) => {
  const newErrors = []
  if (!data.noMatchReason) {
    newErrors.push(['Not Found', 'Not Found reason is required'])
  }
  return {
    hasErrors: newErrors.length > 0,
    errors: Object.fromEntries(newErrors),
    data,
  }
}
const validateFormData = (data) => {
  const newErrors = []
  if (!data.authorityRegion) {
    newErrors.push(['License Authority Region', 'Please enter a valid License Authority Region'])
  }
  if (!data.licenseBoard) {
    newErrors.push(['License Authority Board', 'Please enter a valid License Authority Board'])
  }
  if (!data.credentialIdentifier) {
    newErrors.push(['License #', 'Please enter a valid License Number'])
  }
  if (!data.entityType) {
    newErrors.push(['Licensed Entity Type', 'Please enter a valid Licensed Entity Type'])
  }
  if (!data.licenseType) {
    newErrors.push(['License Type', 'Please enter a valid License Type'])
  }
  if (!data.licenseTypeAsIs) {
    newErrors.push(['License Type As Is', 'Please enter a valid License Type As Is'])
  }
  if (!data.licenseStatus) {
    newErrors.push(['License Status', 'Please enter a valid License Status'])
  }
  if(data.entityType !== 'business') {
    if (!data.entityIdentifier.firstName) {
      newErrors.push(['First Name', 'Please complete all individual name fields'])
    }
    if (!data.entityIdentifier.lastName) {
      newErrors.push(['Last Name', 'Please complete all individual name fields'])
    }
  }
  if(data.entityType !== 'individual'){
    if (!data.entityIdentifier.businessName) {
      newErrors.push(['Business Name', 'Please complete both name fields'])
    }
  }

  return {
    hasErrors: newErrors.length > 0,
    errors: Object.fromEntries(newErrors),
    data,
  }
}

const ManualProfile = ({ manualTask, setErrorDialog }) => {
  const { state } = useLocation()
  const navigator = useNavigate()
  
  const [data, setData] = useState({})
  const [files, setFiles] = useState({})
  const [formErrors, setFormErrors] = useState({})
  const [submitting, setSubmitting] = useState(false)

  const api = useContext(ApiContext)
    
  const profiles = useMemo(() => {
    console.log('Work in task ', manualTask)
    const { rejectReasons = [], profiles = [] } = manualTask.taskDetails || {}
    const reasonMap = Object.fromEntries(rejectReasons.map(({ key, reasons }) => ([key, reasons])) || [])
    const reasonOrder = Object.fromEntries(rejectReasons.map(({ key }, index) => ([key, index])))

    const vals = profiles.filter((profile) => profile) || []
    const mapped = vals.map((profile = {}, index) => {
      // TODO: Support multiple credentials and do this mapping server-side
      const credential = profile && profile.credentials && profile.credentials[0] || {}
      
      const {
        profileId,
        phoneNumbers,
        addresses,
      } = profile;
      const {
        credentialId,
        credentialIdentifier,
        licenseAuthorityRegion,
        licenseAuthorityBoard,
        licenseType,
        licenseTypeAsIs,
        licensedEntityType,
        licensedEntityIdentifier: {
          businessName,
          firstName,
          middleName,
          lastName,
        },
        expiryDate,
        licenseStatus,
        licenseStatusAsIs,
      } = credential;
      
      const rejectReasons = reasonMap[`${profileId}:${credentialId}`] || []
      return {
        id: `${profileId}:${credentialId}`,
        profileId,
        credentialId,
        index,
        label: credentialIdentifier,
        credentialIdentifier,
        licenseAuthorityRegion,
        licenseAuthorityBoard,
        licenseType,
        licenseTypeAsIs,
        licensedEntityType,
        expiryDate: expiryDate ? new Date(expiryDate) : '',
        licenseStatus,
        licenseStatusAsIs,
        rejectReasons,
        businessName,
        firstName,
        middleName,
        lastName,
        phoneNumber: phoneNumbers[0],
        address: addresses[0],
      }
    })
    return mapped.sort(({ id: a }, { id: b }) => reasonOrder[a] - reasonOrder[b])
  }, [manualTask])

  const verificationOrder = useMemo(() => {
    const {
      verificationId,
      businessName,
      firstName,
      middleName,
      lastName,
      phoneNumber,
      address,
      rejectedResult,
      matchedProfile,
      
    } = manualTask.taskDetails.verificationOrder || {};
    const {
      licensedEntityType,
      credentialIdentifier,
      licenseAuthorityRegion,
      licenseType,
      additionalInformation,
    } = manualTask.taskDetails.verificationOrder?.credentialRequest || {};
    const {
      credentialId,
      licensedEntityIdentifier,
      licenseePhoneNumber,
      licenseeAddress,
      credentialIdentifier: credentialIdentifierResult,
      expiryDate,
      ...rest
    } = manualTask.taskDetails.verificationOrder?.latestResult || {};
    const {
      proof,
    } = matchedProfile || {}
    return {
      verificationId,
      businessName,
      firstName,
      middleName,
      lastName,
      phoneNumber,
      address,
      licensedEntityType,
      credentialIdentifier,
      licenseAuthorityRegion,
      licenseType,
      additionalInformation,
      rejectedResult,
      proof,
      latestResult: {
        id: credentialId,
        credentialId,
        index: 0,
        label: credentialIdentifierResult,
        credentialIdentifier: credentialIdentifierResult,
        expiryDate: expiryDate ? new Date(expiryDate) : '',
        rejectReasons: [],
        phoneNumber: licenseePhoneNumber,
        address: licenseeAddress,
        ...licensedEntityIdentifier,
        ...rest,
      },
    }
  }, [manualTask])




  const dataUpdater = useMemo(() => {
    return (field, value) => {
      setData({
        ...data,
        [field]: value,
      })
    }
  }, [data, manualTask])

  const completionType = useMemo(() => {
    
    const { rejectedResult, latestResult } = verificationOrder || {}
    console.log('completionType', rejectedResult, latestResult)
    if (rejectedResult) {
      return 'No Match Found'
    }
    if (latestResult) {
      const { profileId, credentialId } = latestResult
      if (profiles.some(({ id }) => id === `${profileId}:${credentialId}`)) {
        return 'Found Profile Matched'
      }
      return 'New Profile Manually Created'
    }
    return 'Not Completed'
  }, [verificationOrder, profiles])

  const selectAction = useMemo(() => {
    return (actionType, profileIndex) => {
      setData({
        ...data,
        actionType,
        profileIndex,
      })
    }
  }, [data])

  const submitAction = useMemo(() => {
    const submitSelectExisting = async () => {
      const profile = profiles[data.profileIndex]
      const params = {
        taskId: manualTask.taskId,
        verificationOrderId: verificationOrder.verificationId,
        matchedProfileId: profile.profileId,
        matchedCredentialId: profile.credentialId,
        matchType: 'manual',
        matchTime: Date.now(),
        matchReasons: profile.rejectReasons,
        externalComment: cleanseValue(data.externalComment),
        // An empty string should not be submitted for the externalUrl
        externalUrl: data.externalUrl ? cleanseValue(data.externalUrl) : undefined,
      }
      // No validation for this case with no required fields
      setSubmitting(true)
      await api.createNewMatch(params)
      setSubmitting(false)
      navigator('/home')
    }

    const submitCreateNewMatch = async () => {
      setFormErrors({})
      const uploadedProof = Object.values(files).map(({ fileName, contentType }) => ({ fileName, contentType }))
      console.log('Uploading proof', files, uploadedProof)
      const params = {
        taskId: manualTask.taskId,
        verificationOrderId: verificationOrder.verificationId,
        entityIdentifier: {
          businessName: cleanseValue(data.businessName),
          firstName: cleanseValue(data.firstName),
          middleName: cleanseValue(data.middleName),
          lastName: cleanseValue(data.lastName),
          // DBA?
        },
        entityType: cleanseValue(data.licensedEntityType),
        credentialIdentifier: cleanseValue(data.credentialIdentifier),
        licenseType: cleanseValue(data.licenseType),
        licenseTypeAsIs: cleanseValue(data.licenseTypeAsIs),
        licenseStatus: cleanseValue(data.licenseStatus),
        licenseStatusAsIs: cleanseValue(data.licenseStatusAsIs),
        authorityRegion: cleanseValue(data.licenseAuthorityRegion),
        licenseBoard: cleanseValue(data.licenseAuthorityBoard),
        // TODO: Handle dates better
        expiryDate: data.expiryDate?.toMillis() || null,
        issueDate: Date.now(),
        externalComment: cleanseValue(data.externalComment),
        // An empty string should not be submitted for the externalUrl
        externalUrl: data.externalUrl ? cleanseValue(data.externalUrl) : undefined,
        uploadedProof,
      }
      const { hasErrors, errors } = validateFormData(params)
      if(hasErrors){
        setFormErrors(errors)
        return
      }
      setSubmitting(true)
      const results = await api.createMatchingProfile(params)
      console.log("Got results", results)
      const { profileId, credentialId, uploadedProofs } = results
      if (uploadedProofs.length > 0) {
        const mergedProof = uploadedProofs.map(({ uploadUrl, fileName, contentType, documentId }) => ({ uploadUrl, fileName, contentType, documentId, data: files[fileName].file }))
        
        await Promise.all(mergedProof.map((file) => api.uploadDocument(file)))
        // Then trigger the "completeProofUpload" api
        const proofCompleteInput = {
          profileId,
          credentialId,
          proofTime: Date.now(),
          completedProof: mergedProof.map(({ documentId, contentType, fileName }) => ({ documentId, contentType, fileName })),
        }
        const completeResult = await api.completeProofUpload(proofCompleteInput)
        console.log('Complete results', completeResult)
      }

      setSubmitting(false)
      navigator('/home')
    }
    const submitNoMatch = async () => {
      setFormErrors({})
      const params = {
        taskId: manualTask.taskId,
        verificationOrderId: verificationOrder.verificationId,
        noMatchReason: cleanseValue(data.noMatchReason),
        externalComment: cleanseValue(data.externalComment),
        // An empty string should not be submitted for the externalUrl
        externalUrl: data.externalUrl ? cleanseValue(data.externalUrl) : undefined,
      }
      const { hasErrors, errors } = validateNotFound(params)
      if(hasErrors){
        setFormErrors(errors)
        return
      }
      setSubmitting(true)
      await api.createNoMatch(params)
      setSubmitting(false)
      navigator('/home')
    }

    // TODO: Visually display the loading screen upon selection
    // TODO: Create blocking confirmation screen as well
    return async () => {
      console.log('Submitting data', data)
      try {
        switch (data.actionType) {
          case ActionTypes.selectExisting:
            return await submitSelectExisting()
          case ActionTypes.createNew:
            return await submitCreateNewMatch()
          case ActionTypes.noMatch:
            return await submitNoMatch()  
            
          default:
            throw new Error(`actionType ${data.actionType} was not set to a supported value. Please check and retry`)
        }
      }
      catch (e) {
        setSubmitting(false)
        console.log('Error', e.message, e)
        setErrorDialog({
          show: true,
          errorMessage: e.message,
        })
      }
    }
  }, [data, files, manualTask])

  useEffect(() => {
    if (verificationOrder?.latestResult) {
      console.log('Setting matched display')
      selectAction(ActionTypes.displayMatched)
    }
  }, [verificationOrder])

  return (
    <TaskDisplay 
      state={state}
      manualTask={manualTask}
      verificationOrder={verificationOrder}
      profiles={profiles}
      selectAction={selectAction}
      data={data}
      dataUpdater={dataUpdater}
      formErrors={formErrors}
      submitAction={submitAction}
      submitting={submitting}
      setData={setData}
      setFiles={setFiles}
      files={files}
      completionType={completionType} />
  )
}

const TaskDisplay = ({ state, manualTask, verificationOrder, profiles, selectAction, data,
  dataUpdater, formErrors, submitAction, submitting, setData, setFiles, files, completionType }) => {
  if (!manualTask.taskId) {
    return (
      <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    )
  }
  if (manualTask.status === 'COMPLETED' && !state?.resubmit) {
    return (
      <CompletedTicketDetail 
        manualTask={manualTask}
        verificationOrder={verificationOrder}
        profiles={profiles}
        data={data}
        completionType={completionType} />
    )
  }
  return <PendingTicketDetail
    manualTask={manualTask}
    verificationOrder={verificationOrder}
    profiles={profiles}
    selectAction={selectAction}
    data={data}
    dataUpdater={dataUpdater}
    formErrors={formErrors}
    submitAction={submitAction}
    submitting={submitting}
    setData={setData}
    setFiles={setFiles}
    files={files} />
}

export default ManualProfile
