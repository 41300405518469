
export const ActionTypes = {
  createNew: 'createNew',
  selectExisting: 'selectExisting',
  noMatch: 'noMatch',
  displayMatched: 'displayMatched',
}

export const LICENSE_TYPES = [
  { value: 'dentistry', label: 'Dentistry' },
  { value: 'homeServices', label: 'Home Services' },
  { value: 'architecture', label: 'Architects' },
  { value: 'professionalBeauty', label: 'Professional Beauty' },
  { value: 'other', label: 'Other' },
]

export const LICENSE_STATUS = [
  { value: 'Active', label: 'Active' },
  { value: 'Inactive', label: 'Inactive' },
]

export const STATE_MAPPING = {
  'AL': 'AL (Alabama)',
  'AK': 'AK (Alaska)',
  'AZ': 'AZ (Arizona)',
  'AR': 'AR (Arkansas)',
  'CA': 'CA (California)',
  'CO': 'CO (Colorado)',
  'CT': 'CT (Connecticut)',
  'DE': 'DE (Delaware)',
  'DC': 'DC (District of Columbia)',
  'FL': 'FL (Florida)',
  'GA': 'GA (Georgia)',
  'HI': 'HI (Hawaii)',
  'ID': 'ID (Idaho)',
  'IL': 'IL (Illinois)',
  'IN': 'IN (Indiana)',
  'IA': 'IA (Iowa)',
  'KS': 'KS (Kansas)',
  'KY': 'KY (Kentucky)',
  'LA': 'LA (Louisiana)',
  'ME': 'ME (Maine)',
  'MD': 'MD (Maryland)',
  'MA': 'MA (Massachusetts)',
  'MI': 'MI (Michigan)',
  'MN': 'MN (Minnesota)',
  'MS': 'MS (Mississippi)',
  'MO': 'MO (Missouri)',
  'MT': 'MT (Montana)',
  'NE': 'NE (Nebraska)',
  'NV': 'NV (Nevada)',
  'NH': 'NH (New Hampshire)',
  'NJ': 'NJ (New Jersey)',
  'NM': 'NM (New Mexico)',
  'NY': 'NY (New York)',
  'NC': 'NC (North Carolina)',
  'ND': 'ND (North Dakota)',
  'OH': 'OH (Ohio)',
  'OK': 'OK (Oklahoma)',
  'OR': 'OR (Oregon)',
  'PA': 'PA (Pennsylvania)',
  'PR': 'PR (Puerto Rico)',
  'RI': 'RI (Rhode Island)',
  'SC': 'SC (South Carolina)',
  'SD': 'SD (South Dakota)',
  'TN': 'TN (Tennessee)',
  'TX': 'TX (Texas)',
  'UT': 'UT (Utah)',
  'VT': 'VT (Vermont)',
  'VA': 'VA (Virginia)',
  'WA': 'WA (Washington)',
  'WV': 'WV (West Virginia)',
  'WI': 'WI (Wisconsin)',
  'WY': 'WY (Wyoming)',
}

export const STATE_LIST = Object.entries(STATE_MAPPING).map(([value, label]) => ({ value, label }))
