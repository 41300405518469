import React from 'react'
import { Typography, Button, TextField, Grid, Card, CardContent, CardHeader, CardActions } from '@mui/material';

export const DisplayTool = ({ tool, initialInputs = {}, executeTool }) => {
  const argTypeMap = React.useMemo(() =>
    Object.fromEntries(tool.arguments.map(({ name, type }) => ([name, type]))), [tool])
  const submitTool = (evt) => {
    evt.preventDefault()
    console.log('submitting tool2', tool, evt)
    const data = new FormData(evt.target);
    const args = [...data.entries()].map(([name, value]) => ({ name, value: { type: argTypeMap[name], value } }))
    console.log('got args2', args)
    executeTool(tool, {
      toolArn: tool.arn,
      mode: 'query',
      arguments: args,
    })
  }
  return (
    <>
      <Card component='form' onSubmit={submitTool}>
        <CardHeader title={tool.name} />
        <CardContent>
          <Typography variant='body'>
            {tool.description}
          </Typography>
          <Grid container spacing={1}>
            {tool.arguments.map((arg) =>
              <>
                <Grid item xs={4}>
                  <TextField fullWidth label={arg.name} name={arg.name} defaultValue={initialInputs[arg.name]?.value} />
                </Grid>
                <Grid item xs={8}>
                  <Typography variant='body'>{arg.description}</Typography>
                </Grid>
              </>,
            )}
          </Grid>
        </CardContent>
        <CardActions>
          <Button variant='outlined' type='submit'>Submit</Button>
        </CardActions>
      </Card>
    </>
  )
}