import React from 'react';
import { Grid, Typography } from '@mui/material';

import { dateMapper, stateMapper } from '../../../../utils/fieldRenderer';
import { formatAddress } from '../../../../utils/formatAddress';

export const DataRows = ({ claimData, record, outputResults = [] }) => {
  return (
    <>
      {outputResults.map((claimConfig, idx) => (
        <DataRow
          key={`record-data-${idx}`}
          claimConfig={claimConfig}
          record={record}
          claimData={claimData}
        />
      ))}
    </>
  );
}

const DataRow = ({ claimConfig, record, claimData }) => {
  const { contents } = record;
  const isJurisdiction = claimConfig.fieldName === 'licenseRegion'
  const isBusinessAddress = claimConfig.fieldName === 'businessAddress'
  const showValue = (value, highlight) => {
    const color = highlight && !value ? 'error.main' : ''
    let guardedValue = value
    if (guardedValue) {
      if (claimConfig.dataType === 'date') {
        guardedValue = dateMapper(value)
      }
      if (isJurisdiction) {
        guardedValue = stateMapper(value)
      }
      if (isBusinessAddress) {
        guardedValue = formatAddress(value)
      }
    }
    return (
      <Typography align='left' sx={{ color }}>
        {guardedValue || '-'}
      </Typography>
    );
  }
  const claimFieldName = isJurisdiction ? 'jurisdiction' : claimConfig.fieldName
  return (
    <>
      <Grid item xs={3} sx={{ borderRight: 1 }}>
        <Typography align='left'>{claimConfig.label}</Typography>
      </Grid>
      <Grid item xs={4} sx={{ borderRight: 1 }}>
        {showValue(claimData[claimFieldName])}
      </Grid>
      <Grid item xs={5}>
        {showValue(contents[claimFieldName], true)}
      </Grid>
    </>
  );
};
