import React, { useState, useContext, useMemo, useEffect } from 'react'
import { Grid, Card, CardContent, Box, CircularProgress, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import ApiContext from '../../../../Services/apiService';
import { STATE_LIST } from '../../../../utils/constants'
import { RetriggerVerificationFooter } from '../../Shared/RetriggerVerificationFooter';
import { RecordFieldValue } from '../../../../Components/RecordFieldValue'
import ProofUploader from '../../Shared/ProofUploader';
import { milliSecondsFromDateString } from '../../../../utils/formatDate';

const findInnerError = (err) => {
  // This is a regular JS exception
  if (err?.message) {
    return err.message
  }
  // This is a GraphQL error message array
  if (Array.isArray(err?.errors)) {
    const [{ message }] = err.errors
    return message  
  }
}
const mapOutputValue = ({ name, value, recordConfig }) => {
  const resultConfig = recordConfig.outputResults.filter(({ fieldName }) => fieldName === name)[0]
  if (resultConfig && resultConfig.dataType === 'date') {
    return milliSecondsFromDateString({ dateString: value })
  }
  return value
}

const CreateRecord = ({ manualTask, setErrorDialog }) => {
  const navigator = useNavigate()
  const api = useContext(ApiContext)
  const [submitting, setSubmitting] = useState(false)
  const [found, setFound] = useState(undefined)
  const [files, setFiles] = useState([])
  const completed = manualTask.status === 'COMPLETED'
  // TODO:
  // Validation of values
  const recordConfig = useMemo(() => manualTask?.taskDetails?.recordConfig, [manualTask])

  const handleSubmit = useMemo(() => {
    return async (evt) => {
      evt.preventDefault();
      const data = new FormData(evt.target);
      const uploadedProof = Object.values(files).map(({ fileName, contentType }) => ({ fileName, contentType }))
      const input = {
        taskId: manualTask.taskId,
        foundResults: found,
        results: [...data.entries()]
          .map(([name, value]) => ({ name, value: mapOutputValue({ name, value, recordConfig }) }))
          .filter(({ value }) => value !== ''),
        uploadedProof,
      }
      setSubmitting(true)
      try {
        const { uploadedProofs } = await api.submitCreateRecord(input)
        if (uploadedProofs && uploadedProofs.length) {
          await Promise.all(
            uploadedProofs.map(({ uploadUrl, fileName, contentType, documentId }) => (api.uploadDocument({ uploadUrl, fileName, contentType, documentId, data: files[fileName].file }))))
          await api.completeProofUploadInventory({taskId: input.taskId, foundResults: input.foundResults, results: input.results, completedProof: uploadedProofs.map(({ documentId, contentType, fileName }) => ({ documentId, contentType, fileName }))})
        }
        setSubmitting(false)
        navigator('/home')
      } catch (err) {
        setSubmitting(false)
        console.log('got error', err)
        const inner = findInnerError(err)
        setErrorDialog({
          show: true,
          errorMessage: `There was an error while submitting the form. Please try again or contact #oncall - ${inner}`,
        })
      }
    }
  }, [api, navigator, found, manualTask, files]) 
  
  const { claimData, jurisdictionRecord } = useMemo(() => {
    const claimData = Object.fromEntries(manualTask.taskDetails.claims.map(({ name, value }) => ([name, value])))
    const jurisdictionValue = manualTask.taskDetails.jurisdiction.split('.')[1]
    const jurisdictionRecord = {
      dataType: "enum",
      fieldName: "licenseRegion",
      label: "License Region",
      options:{
        values: STATE_LIST,
      },
      value: STATE_LIST.find(({value}) => value === jurisdictionValue)?.value,
    }
    return { claimData, jurisdictionRecord }
  }, [manualTask])

  return (
    <Box component='form'
      onKeyDown={evt => {
        if (evt.key === 'Enter') {
          evt.preventDefault();
          return false
        }
        return true
      }}
      onSubmit={evt => {
        handleSubmit(evt);
      }}>
      <Card sx={{ p: 1, m: 1 }}>
        <CardContent>
          <Grid container columnSpacing={{ xs: 2 }} rowSpacing={{ xs: 1 }}>
            <Grid item xs={6}>
              <Box>
                Input
                {jurisdictionRecord && <RecordFieldValue 
                    key={`input-${jurisdictionRecord.fieldName}`}
                    name={`input-${jurisdictionRecord.fieldName}`}
                    label={jurisdictionRecord.label}
                    value={jurisdictionRecord.value}
                    fieldType={jurisdictionRecord.dataType}
                    options={jurisdictionRecord.options}
                    editMode={false}
                  />}
                {recordConfig && recordConfig.inputClaims && recordConfig.inputClaims.length && recordConfig.inputClaims.map((field) =>
                  <RecordFieldValue 
                    key={`input-${field.fieldName}`}
                    name={`input-${field.fieldName}`}
                    label={field.label}
                    value={claimData[field.fieldName] || field.value}
                    fieldType={field.dataType}
                    options={field.options}
                    editMode={false}
                  />,
                )}
              </Box>
              
              <Typography>Have you found a match for these inputs?</Typography>
              <Box marginTop={1}>
                <Button variant={found === true ? 'contained' : 'outlined'} fullWidth onClick={() => setFound(true)}>Yes</Button>
              </Box>
              <Box marginTop={1}>
                <Button variant={found === false ? 'contained' : 'outlined'} fullWidth onClick={() => setFound(false)}>No</Button>
              </Box>
            </Grid>
            {found && <Grid item xs={6}>
              <Box>
                Outputs
                {recordConfig.outputResults.map((field) =>
                  <RecordFieldValue 
                    key={`output-${field.fieldName}`}
                    name={field.fieldName}
                    label={field.label}
                    fieldType={field.dataType}
                    options={field.options}
                    editMode={true}
                  />,
                )}
              </Box>
            </Grid>}
          </Grid>
        </CardContent>
      </Card>
      {found && <Card sx={{ p: 1, m: 1 }}>
        <CardContent>
          <Grid container columnSpacing={{ xs: 2 }} rowSpacing={{ xs: 1 }}>
            <Grid item xs={3}>
              <Typography align="left">Attach Proof</Typography>
            </Grid>
            <Grid item xs={9}>
              <ProofUploader files={files} setFiles={setFiles} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>}
      <RetriggerVerificationFooter completed={completed} taskDetails={manualTask?.taskDetails} />
      {!completed && <Card sx={{ p: 1, m: 1 }}>
        <CardContent>
          <Grid container columnSpacing={{ xs: 2 }} rowSpacing={{ xs: 1 }}>
            <Grid item xs={4} />
              
            <Grid item xs={4}>
              <Button disabled={found === undefined} variant='contained' fullWidth type="submit">
                {submitting ? <CircularProgress color="secondary" size={20} /> : 'Submit findings'}
              </Button>
            </Grid>
            <Grid item xs={4} />
          </Grid>
        </CardContent>
      </Card>}
    </Box>
  )

}

export default CreateRecord